<template>
  <div>
    <div class="container py-24">
      <div class="text-center">
        <h2 class="text-4xl">
          {{ desc }}
        </h2>
        <p class="text-xl mt-4 flex items-center justify-center cursor-pointer">
          {{ selectDesc }}
          <span
            class="ml-3 text-primary text-base hover:underline"
            @click="dialog.toggle({ show: true })"
          >
            <font-awesome-icon :icon="['fas', 'question-circle']" />
            說明
          </span>
        </p>
      </div>
      <div
        class="flex space-x-12 mt-8 justify-center items-center md:flex-col md:space-x-0"
      >
        <div class="w-[380px] space-y-6 md:w-full">
          <Redeem-Btn></Redeem-Btn>
          <div
            v-for="row in rows"
            :key="row.id"
            class="rounded bg-primary-light shadow p-6 flex flex-col items-center duration-500 hover:border-primary hover:bg-white border border-transparent hover:shadow-lg"
          >
            <div class="text-4xl text-primary font-bold">{{ row.title }}</div>
            <div class="text-lg mt-4">{{ row.scope }}</div>
            <p class="text-gray mt-2">{{ row.info }}</p>
            <el-button
              type="primary"
              size="default"
              class="mt-6"
              @click="toPlan(row)"
              >選擇方案</el-button
            >
          </div>
        </div>
        <img src="@/assets/imgs/price-role.png" alt="" class="md:mt-12" />
      </div>
    </div>
    <El-Dialog
      v-model="dialog.state.show"
      destroy-on-close
      custom-class="!max-w-md"
    >
      <Info></Info>
    </El-Dialog>
  </div>
</template>
<script setup>
import { ref, reactive, inject } from 'vue';
import { ElDialog } from 'element-plus';
import Payment from '@/api/Payment.js';
import Info from '@/modules/shop/Info.vue';
import useDialog from '@/hook/useDialog';
import RedeemBtn from '@/modules/coupon/redeem/Btn.vue';
import { useRouter } from 'vue-router';

// const version = inject('version');
const router = useRouter();

const auth = inject('auth');

const dialog = useDialog();
const { rows, desc, selectDesc } = inject('typesData');

const toPlan = (row) => {
  if (!auth.guard()) return;

  router.push({
    name: 'Shop.Plan',
    query: {
      typeId: row.id,
      is_sub: row.is_sub,
    },
  });
};
</script>
