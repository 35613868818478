<template>
  <div
    class="rounded bg-primary-light shadow p-6 flex flex-col items-center duration-500 hover:border-primary hover:bg-white border border-transparent hover:shadow-lg"
  >
    <div class="text-4xl text-primary font-bold">書籍題庫啟用</div>
    <div class="text-lg mt-4">搭配書籍,有效期間內無限使用該科題庫</div>
    <el-button type="primary" size="default" class="mt-6" @click="open"
      >馬上啟用</el-button
    >
    <el-dialog
      v-model="dialogRedeem.state.show"
      custom-class="md:w-[94%] w-[360px]"
      :destroy-on-close="true"
    >
      <Redeem
        :title="'書籍題庫啟用'"
        :info="'請輸入書籍附贈的題庫啟用碼 <br /> 立即啟用該科目線上題庫！'"
        @close="dialogRedeem.toggle({ show: false })"
      ></Redeem>
    </el-dialog>
  </div>
</template>

<script setup>
import { inject } from 'vue';
const auth = inject('auth');
import useDialog from '@/hook/useDialog';
import { ElDialog } from 'element-plus';
import Redeem from '@/modules/coupon/redeem/index.vue';
const dialogRedeem = useDialog();
const open = () => {
  if (!auth.guard()) return;
  dialogRedeem.toggle({ show: true });
};
</script>
