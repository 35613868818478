<template>
  <div class="flex flex-col divide-y px-12">
    <div v-for="row in rows" :key="row.id" class="py-8">
      <h3 class="text-2xl text-primary">{{ row.title }}</h3>
      <div class="mt-2 text-gray text-lg">{{ row.scope }}</div>
      <p class="text-info-4 mt-1">
        {{ row.info }}
      </p>
    </div>
  </div>
</template>
<script setup>
import Payment from '@/api/Payment.js';
import { reactive } from 'vue';
const rows = reactive([]);
const fetcher = () => {
  Payment.infos().then((res) => {
    if (res.code === 1) {
      rows.push(...res.data.rows);
    }
  });
};
fetcher();
</script>
